<template>
  <div class="page_container">
    <!-- 静态图片 -->
    <div class="about_banner">
      <img src="../../assets/image/about/join.jpg" alt />
    </div>
    <!-- 导航条 -->
    <div class="about_nav">
      <div class="about_nav_box">
        <ul class="about_nav_detail">
          <li>
            <router-link to="/about">公司简介</router-link>
          </li>
          <li>
            <router-link to="/prize">荣誉资质</router-link>
          </li>
          <li>
            <router-link to="/join">加入我们</router-link>
          </li>
          <li>
            <router-link to="/contact">联系我们</router-link>
          </li>
        </ul>
      </div>
    </div>
    <!-- 职位详情 -->
    <div class="position_details">
      <div class="back_btn">
        <i class="el-icon-back"></i>
        <router-link to="/join">全部职位</router-link>
      </div>
      <div class="web_details">
        <div class="web_title">java高级研发工程师</div>
        <div class="web_duty_box">
          <p class="web_duty_title">岗位职责：</p>
          <p class="web_duty_details">
            1、 负责企业业务系统的需求分析.技术方案设计.核心功能开发和维护等工作;
            <br />2、 负责解决项目中关键问题和技术难题，线上疑难问题排查并能给出合理的解决方案;
            <br />3、 负责可用性.性能和稳定性建设，确保大用户量使用情况下的体验;
            <br />4、 与团队内部各个保持沟通，提供基础架构相关的问题解决答疑;
          </p>
        </div>
        <div class="web_require">
          <p class="web_duty_title">任职要求：</p>
          <p class="web_duty_details">
            1、 本科以上学历，211/985学院优先;<br>
            2、 扎实的计算机基础理论.数据结构和算法功底，优秀的编程能力;<br>
            3、 具有5年以上的java开发经验，熟练使用Spring Cloud组建进行服务管理与维护,掌握一定的系统架构技巧;<br>
            4、 熟练使用 Hibernate\JWT&OAuth2\MQ\NoSql数据库等技术栈进行业务开发;<br>
            5、 熟悉linux, 掌握Jenkins\nginx\docker等应用服务配置;<br>
            6、 有SAAS系统和开发经验者优先;<br>
            7、 具备良好的沟通技能和团队合作能力，有较强的独立工作能力和解决问题能力。
          </p>
        </div>
        <div class="web_contact">
                    如有意向，可发送简历至：
                    <span class="blue_color">hr@bjblackhole.com，</span>
                    或联系：
                    <span class="blue_color"> 136 2850 8343.</span>
                </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  }
}
</script>

<style scope>
</style>
